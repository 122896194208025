import { useState, useEffect } from "react";
 

export default function HomePage(){
  const investInfo = [
    { 
      title : "Arts & Entertainment",
      info :"Our investment firm sees great potential in the Arts & Entertainment sector due to its dynamic nature and the ever-evolving consumer demand for cultural experiences. With technological advancements shaping the way content is consumed, we aim to invest in companies that exhibit strong adaptability and innovative approaches to content creation and distribution. The sector's ability to capture audience engagement across various platforms positions it as a compelling avenue for growth in a digitally driven world.",
      color : "#700548"
    },   
   {
    title : "Land",
    info : "Investing in land aligns with our investment firm's strategy of pursuing stable, tangible assets with potential long-term appreciation. Land is a finite resource, and as urbanization and development continue, strategic land holdings become increasingly valuable. By carefully analyzing local market trends, zoning regulations, and growth projections, we aim to make investments that leverage the potential for future development while maintaining a focus on risk mitigation"
      ,
    color : "#7272AB"},

     { title :"Education",
     info :
     "Our investment firm recognizes the transformative power of education and the role it plays in shaping societies and economies. We are drawn to investments in education technology and platforms that facilitate accessible and innovative learning experiences. By supporting advancements in online education, vocational training, and skill development, we aim to contribute to a more knowledgeable and capable workforce, thereby fostering sustainable growth."
    ,color : "#7899D4"
    }  ,
      {
        
        title : "Capital",
        info : "Investing in capital-intensive industries resonates with our commitment to driving operational efficiency and productivity. Our firm focuses on identifying companies that prioritize strategic capital investments, leading to enhanced production capabilities and competitive advantages. By aligning with industries poised for growth, we position ourselves to benefit from the synergy between capital investment and long-term profitability."
    ,  color : "#362023"
      },
    {  
      
      title : "Family",
      info :"Our investment firm values investments that cater to the diverse needs of families. We recognize the significance of family-oriented products and services, ranging from healthcare solutions to entertainment options that foster quality family time. By investing in companies that understand and address these needs, we aim to generate returns while contributing to the well-being and convenience of modern families.",
      color : "#080705"
     } ,
     { 
      title : "Labour",
      info :"Investing in the labor sector underscores our commitment to ethical and social considerations. We seek opportunities that prioritize fair labor practices, employee well-being, and workforce development. Our firm's focus on labor-related investments reflects our belief that fostering positive work environments and providing growth opportunities can lead to both financial success and social impact.",
    color : "#896A67",
    } , 
     { 
      title : "Media",
    info :
      "The Media sector aligns well with our investment firm's interest in the evolving landscape of content consumption. We see potential in companies that leverage digital platforms, adapt to changing consumer behaviors, and capitalize on emerging advertising models. By investing in media entities that navigate regulatory challenges and offer diverse content portfolios, we position ourselves to capitalize on the sector's growth trajectory."
     ,
    color : "#6B4D57"
    } ,
     { 
      title : "Government & Politics",
      info :
      "Our investment firm recognizes the interconnectedness of economics and politics. We strategically invest in companies that demonstrate an understanding of government policies and are positioned to thrive within regulatory frameworks. Our approach is informed by a commitment to ethical governance and transparency, ensuring that our investments align with both financial objectives and societal values." ,
      color : "#896A67" 
    } 
    , 
    { 
      title : "Entrepreneurship", 
      info : "Investing in entrepreneurship speaks to our firm's dedication to fostering innovation and supporting the growth of small businesses. We recognize the potential for high returns within the startup ecosystem, and we take a diversified approach to manage risks associated with early-stage ventures. Our goal is to identify promising entrepreneurs, provide them with the necessary resources, and share in their journey towards building successful enterprises.",
      color : "#3C493F"},
     { title :"Economy" , info : 
      "Our investment firm's approach to the Economy sector is rooted in a deep understanding of macroeconomic trends and indicators. We seek investments that align with different economic cycles, positioning our portfolio to withstand fluctuations and capitalize on opportunities across industries. Our commitment to diversification allows us to navigate the ever-changing economic landscape while aiming for sustainable returns.", color :"#B9314F"}
    ];

    const paragraphs = [
      {
 title : "Sustainability",
 info : "Long standing businesses with clear sustainable models"
      }  , 
       {
         title : "Emerging Markets",
         info : "Beyond Europe, we acquire businesses from Africa and the Carribeans"
 
       } , 
         {
           title : "Profitability",
           info : "We are looking for businesses that have figured market-fit"
         }
       ];
    const [currentTime, setCurrentTime] = useState(new Date());
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [selectedIdx, setSelectedIdx] = useState(0);
    const selectedParagraph = selectedIdx !== null ? paragraphs[selectedIdx] : null;

    const [selectedSector, setSelectedSector] = useState(investInfo[0].title);
const selectedInfo = investInfo.find((info) => info.title === selectedSector)?.info;
const selectedColor = investInfo.find((info) => info.title === selectedSector)?.color;

const handleSectorClick = (sector) => {
  if (selectedSector !== sector) {
    setIsTransitioning(true);
    setTimeout(() => {
      setSelectedSector(sector);
      setIsTransitioning(false);
    }, 500); // Adjust the duration as needed (500ms in this example)
  }
};



    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentTime(new Date());
      }, 1000); // Update every 1000 milliseconds (1 second)
  
      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, []);

    const formatDate = (date) => {
        const options = { weekday: 'short', month: 'short', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      };
    
      const formatTime = (date) => {
        return date.toLocaleTimeString();
      };


      const handleClick = () => {
        window.location.href = "https://forms.gle/sNQu37VPCJyq6zPm9";
      };

    



     
   
   return <div className="flex flex-col">
   <header className="px-4 py-4 flex-row flex items-center justify-between">

<div className="flex flex-row gap-2 items-center">
    <img src="/images/Logo 05.svg" color="#FFFFFF"  width={30} height={30} />
<p className="font-giga ml-2 font-semibold">BRIGHT STAR</p>
</div>
<div className="font-giga flex flex-row gap-3 text-[10px] sm:text-[15px]">
    <p>about</p>
    <p>contact</p>
</div>

   </header>
   <div class="relative h-[500px]">
  <img src="/images/bg.jpg" alt="Background Image" class="w-full h-full object-cover" />
  <div className="absolute top-0 left-0 w-full h-full bg-black opacity-20"></div>
  <div className="absolute top-1/2 bottom-1 px-4 pb-8  text-white">
    <p className=" font-bold text-[28px] sm:text-[30px] font-peta">THE FUTURE IS TODAY</p>
    <p className="font-bold text-[28px] sm:text-[30px] font-peta">WE INVEST IN IT</p>
 
  </div>
</div>

   <div className="font-peta mb-[100px] mt-[150px] px-4 relative items-start ">
  
  <div className="flex flex-row items-center mb-2">
  <img src="/images/Logo 07.svg"  className="w-[30px] h-[30px] sm:h-[50px] sm:w-[50px]" />


<div className="">
<p className="text-[20px] sm:text-[50px] font-bold ml-[10px]">Who We Are</p>

</div>

  </div>
  <p className="text-[10px] sm:text-[20px]">Bright Star Enterprises is a leading Mergers & Acquisitions organisation based in the UK, specialising
in the acquiring, investing & growing of private companies located in Europe, Africa and the
Caribbeans. BSE primarily buys long standing family business from business owners without a
succession plan and desiring a respectable exit from the business.</p>


  
     </div>

     <div className="flex flex-col sm:flex-row mt-[100px] h-[500px]">
        <div className="sm:w-[50%] h-[200px] sm:h-full items-end font-peta  text-[20px] sm:text-[50px] px-4 py-10 flex flex-col  justify-center text-white  bg-black">
          <p>BEYOND</p>
          <p>INVESTING</p>
        </div>
        <div className="sm:w-[50%] flex flex-col justify-center">
        <p className="font-peta py-4 px-4 text-[10px] sm:text-[20px]">
        Once companies are purchased, our aim is to build on the great work from previous owners so
therefore it goes far beyond a traditional acquisition. Post-acquisition, we aim to ensure purchased
companies are fully transformed to fit the 21 st Century and the application of digital technologies to
create new or enhance existing customer experiences. Business processes and models will be
implemented along with the traditional HR, Financial, Operational, Marketing functionality.
        </p>
        </div>
      
     </div>

     {/* <hr  className="w-1/2 self-center border-2 rounded-sm border-[#ABABAB]"/> */}
<div className="px-4 mt-[100px]">
<p className="text-[20px] font-peta sm:text-[30px] text-black mb-4 font-bold">OUR SECTORS</p>

<div   style={{ backgroundColor: selectedColor }} className={` rounded-xl font-peta transition-colors delay-500 duration-500 ${isTransitioning ? "bg-opacity-0" : "bg-opacity-100"} text-white px-4 py-20 shadow-lg mb-15 `}>     

  <div className="scrollbar-hide mb-6 flex   items-center text-center flex-row font-peta text-[6px] sm:text-[8px] gap-4 overflow-scroll">
             {investInfo.map((sectorInfo, index) => (
      <p
        key={index}
        className={`${ 
          sectorInfo.title === selectedSector ? " font-bold delay-500 text-[12px]" : ""
        } cursor-pointer `   }
       
        onClick={() => handleSectorClick(sectorInfo.title)}
      >
        {sectorInfo.title}
      </p>
    ))}

        </div>
     
<div className="flex  flex-col items-center sm:flex-row gap-4 justify-between">
<div className="flex flex-col items-center justify-around">

<div className={`shadow-xl bg-white relative w-[300px] h-[300px] rounded-full flex flex-col transition-colors delay-500 duration-500 ${isTransitioning ? "bg-opacity-0" : "bg-opacity-100"} items-center justify-center bg-inherit border-2 border-white transition-opacity delay-500 duration-500`}>  
  <p style={{ color: selectedColor }}  className={`text-[25px] delay-500 duration-500 transition-colors ${isTransitioning ? "bg-opacity-0" : "bg-opacity-100"} text-center sm:text-[30px] font-bold`}>{selectedSector}</p>
{/* <div className="absolute w-full h-full">
<div className="w-10 h-10 rounded-full bg-[#DDEBFF]  border-white transform rotate-45 absolute top-0 right-0"></div>
<div className="w-10 h-10 rounded-full bg-[#DDEBFF]  border-white transform rotate-135 absolute bottom-0 right-0"></div>
<div className="w-10 h-10 rounded-full bg-[#DDEBFF]  border-white transform -rotate-135 absolute bottom-0 left-0"></div>
<div className="w-10 h-10 rounded-full bg-[#DDEBFF]  border-white transform -rotate-45 absolute top-0 left-0"></div>
</div> */}
</div>
     </div>
   




<p className="text-[12px] px-4 sm:text-[15px] font-giga">{selectedInfo}</p>
       

</div>
   
       
     </div>

</div>
   
   
<div className="px-4 mt-[150px] mb-[150px]">
    <p className="font-peta mb-4">STANDARDS</p>
   
   <div>
   <div className="flex flex-row items-center gap-2">
   <img
        src={`/images/Logo 0${selectedIdx !== null ? selectedIdx : 'default'}.svg`}
        className="w-[60px] h-[60px]"
        alt=""
      />
   <div className="">   
   {selectedParagraph && (
        <p
          onClick={() => setSelectedIdx(null)}
          className="font-peta text-[30px] sm:text-[60px]"
        >
          {selectedParagraph.title}
        </p>
      )}

{paragraphs.map((text, index) => (
        selectedIdx !== index && (
          <p
            key={index}
            onClick={() => setSelectedIdx(index)}
            className={`font-peta text-[12px] text-[#ABABAB] ${index > 0 ? "mt-1" : ""}`}
          >
            {text.title}
          </p>
        )
      ))}
    <p className="font-peta mt-4 text-black text-[12px] sm:text-[25px]">
 {paragraphs[selectedIdx].info}

   </p>
   </div>
    </div>
 

   </div>
   
</div>


     <div className="h-[500px] sm:h-[500px] mt-[100px] bg-black px-4 py-4 flex flex-col  items-center justify-center">
        <p className="font-giga text-[20px] sm:text-[50px] text-white">PITCH YOUR BUSINESS</p>
        <p onClick={()=> {handleClick()}} className="cursor-pointer font-giga text-[#D9D9D9]">get started &#8594;</p>
     </div>
     <div className="flex flex-row py-4 items-center justify-between px-4">
     <div className="font-giga text-[8px] sm:text-[12px] flex flex-row justify-between px-4 w-2/3">
   <div>
   <p>
   {formatDate(currentTime)}
    </p>
    <p>
    {formatTime(currentTime)}
    </p>
   </div>
   <div>
   <p>
     info@brightstar.co.uk
    </p>
    <p>
      +478 976789 90
    </p>
   </div>
   </div>
   <img src="/images/Logo 05.svg"  className="w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]" width={20} height={20} />


     </div>
 
    </div>
}